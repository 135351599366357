import {
  extendBaseTheme,
  theme as chakraTheme,
  ChakraBaseProvider,
} from '@chakra-ui/react';
import { Global } from '@emotion/react';

import uticketTheme from '../theme';

const { Modal } = chakraTheme.components;

const theme = extendBaseTheme({
  ...uticketTheme,
  components: { ...uticketTheme.components, Modal },
});

const CSSChakraReset = () => (
  <Global
    styles={`
          .chakra-text {
              margin: 0;
          }
  
          // h1.chakra-text,
          // h2.chakra-text,
          // h3.chakra-text,
          // h4.chakra-text,
          // h5.chakra-text,
          // h6.chakra-text {
          //   font-size: inherit;
          //   font-weight: inherit;
          // }
      `}
  />
);

export const ChakraProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <ChakraBaseProvider resetCSS={false} disableGlobalStyle theme={theme}>
      <CSSChakraReset />
      {children}
    </ChakraBaseProvider>
  );
};
