import {create} from 'zustand';
import { persist } from 'zustand/middleware';

import { Storages } from 'src/appConstants';

type CookieConsentStore = {
  showModal: boolean;
  hideModal: () => void;
};

const useCookieConsentStore = create<CookieConsentStore>()(
  persist(
    set => ({
      showModal: true,
      hideModal: () => set({ showModal: false }),
    }),
    { name: Storages.COOKIE_CONSENT },
  ),
);

export default useCookieConsentStore;
