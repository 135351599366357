import axios from 'axios';
import flow from 'lodash/flow';

import { Urls } from 'src/appConstants';

import {
  requestFullfilledAuthHeader,
  requestFullfilledCustomHeaders,
  responseFullfilled,
  responseRejected,
  responseRejectedNotFound,
} from './utils';

const checkout = axios.create({
  baseURL: Urls.CHECKOUT_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

checkout.interceptors.request.use(
  flow([requestFullfilledAuthHeader, requestFullfilledCustomHeaders]),
);

checkout.interceptors.response.use(
  responseFullfilled,
  flow([responseRejectedNotFound, responseRejected]),
);

export default checkout;
