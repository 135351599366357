import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { Storages } from 'src/appConstants';
import { Authentication } from 'src/types/dto';

type UserStore = {
  id: number;
  email: string;
  token: string;
  accessToken: string;
  setUser: (user: Authentication) => void;
  clear: () => void;
};

const useUserStore = create<UserStore>()(
  persist(
    (set, get) => ({
      id: 0,
      email: '',
      token: '',
      accessToken: '',
      setUser: ({ id, email, token }: Authentication) =>
        set({ id, email, token, accessToken: btoa(`${email}:${token}`) }),
      clear: () => set({ email: '', accessToken: '' }),
    }),
    { name: Storages.USER },
  ),
);


export default useUserStore;
