import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';

Yup.setLocale(ptForm);

// const Kilobytes = 1024;
// const Megabytes = 1024 * Kilobytes;

// eslint-disable-next-line no-template-curly-in-string
function cellphone(message = '${path} deve ser válido') {
  return this.test({
    name: 'cellphone',
    exclusive: false,
    message,
    test: function (value) {
      return value ? [10, 11].includes(value.replace(/\D/g, '').length) : true;
    },
  });
}
Yup.addMethod(Yup.string, 'cellphone', cellphone);

// eslint-disable-next-line no-template-curly-in-string
function email(message = 'Digite um email valido. Ex: seuemail@gmail.com') {
  return this.test({
    name: 'email',
    exclusive: false,
    message,
    test: function (value) {
      return value
        ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi.test(value)
        : true;
    },
  });
}
Yup.addMethod(Yup.string, 'email', email);

// eslint-disable-next-line no-template-curly-in-string
function password(message = '${path} deve possuir pelo menos 6 dígitos') {
  return this.test({
    name: 'password',
    exclusive: false,
    message,
    test: function (value) {
      return value ? value.length >= 6 : true;
    },
  });
}
Yup.addMethod(Yup.string, 'password', password);

// eslint-disable-next-line no-template-curly-in-string
function cep(message = '${path} deve ser válido') {
  return this.test({
    name: 'cep',
    exclusive: false,
    message,
    test: function (value) {
      return value ? /^\d{2}\.?\d{3}-?\d{3}$/.test(value) : true;
    },
  });
}
Yup.addMethod(Yup.string, 'cep', cep);

// eslint-disable-next-line no-template-curly-in-string
function imageUploadLessThan2Mb(message = '${path} deve ter no máximo 2 MB') {
  return this.test({
    name: 'imageUploadLessThan2Mb',
    exclusive: false,
    message: field => {
      if (!field.value) return `Escolha uma imagem para ${field.path}.`;
      if (field.value.size > 2097152)
        return `${field.path} deve ter no máximo 2 MB`;
      if (!field.value.cropped)
        return `Escolha uma imagem para ${field.path}..`;
      return `${field.path} deve ter no máximo 2 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (!imageUploadValue) return false;
      if (imageUploadValue.size > 2097152) return false;
      return imageUploadValue.size <= 2097152;
    },
  });
}
Yup.addMethod(Yup.mixed, 'imageUploadLessThan2Mb', imageUploadLessThan2Mb);

// eslint-disable-next-line no-template-curly-in-string
function imageUploadLessThan3Mb(message = '${path} deve ter no máximo 3 MB') {
  return this.test({
    name: 'imageUploadLessThan3Mb',
    exclusive: false,
    message: field => {
      if (!field.value) return `Escolha uma imagem para ${field.path}.`;
      if (field.value.size > 3145728)
        return `${field.path} deve ter no máximo 3 MB`;
      if (!field.value.cropped)
        return `Escolha uma imagem para ${field.path}..`;
      return `${field.path} deve ter no máximo 3 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (!imageUploadValue) return false;
      if (imageUploadValue.size > 3145728) return false;
      return imageUploadValue.size <= 3145728;
    },
  });
}
Yup.addMethod(Yup.mixed, 'imageUploadLessThan3Mb', imageUploadLessThan3Mb);

function imageUploadOptionalLessThan2Mb(
  // eslint-disable-next-line no-template-curly-in-string
  message = '${path} deve ter no máximo 2 MB',
) {
  return this.test({
    name: 'imageUploadOptionalLessThan2Mb',
    exclusive: false,
    message: field => {
      return `${field.path} deve ter no máximo 2 MB`;
    },
    // import { ImageUploadValue } from './components/image-upload';
    test: imageUploadValue => {
      if (imageUploadValue && imageUploadValue.size > 2097152) return false;
      return true;
    },
  });
}
Yup.addMethod(
  Yup.mixed,
  'imageUploadOptionalLessThan2Mb',
  imageUploadOptionalLessThan2Mb,
);

// eslint-disable-next-line no-template-curly-in-string
function multipleFilesUpload(message = '${path} deve ter no máximo 2 MB') {
  return this.test({
    name: 'multipleFilesUpload',
    exclusive: false,
    message: message,
    // import { ImageUploadValue } from './components/image-upload';
    test: fileUploadValue => {
      if (fileUploadValue.length > 5) return false;
    },
  });
}
Yup.addMethod(Yup.mixed, 'multipleFilesUpload', multipleFilesUpload);

function quillRichEditor(message = 'O campo é obrigatório.') {
  return this.test({
    name: 'quillRichEditor',
    exclusive: false,
    message: message,
    test: function (value) {
      return !!value.ops?.[0]?.insert && value.ops?.[0]?.insert !== '\n';
    },
  });
}
Yup.addMethod(Yup.object, 'quillRichEditor', quillRichEditor);

export const yup = Yup;

export { yupResolver } from '@hookform/resolvers/yup';

export const mergeRefs = refs => {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
};
