import api, { authApi } from 'src/api';

export const postAuth = (email, password) =>
  authApi
    .post('signin', { email, password }, { params: { newmailkey: 1 } })
    .then(res => res.data);

export const postPasswordRecovery = email =>
  api.post('passwordrecovery', `"${email}"`).then(res => res.data);

export const postUser = ({ name, email, password, cellPhone, utm }) =>
  api
    .post('user', { name, email, password, cellPhone, utm })
    .then(res => res.data);
