import { Suspense, lazy } from 'react';

import InputMask from 'react-input-mask';

import fills from './fills';

import 'react-quill/dist/quill.snow.css';

import './styles.scss';
import { Skeleton } from '@chakra-ui/react';

const ReactQuill = lazy(
  () =>
    import(
      /* webpackChunkName: "ReactQuill" */
      'react-quill'
    ),
);

const Input = ({
  type,
  customType,
  fill,
  value,
  label,
  hideLabel = false,
  wrong = false,
  children,
  format,
  options = [],
  disabled = false,
  expanded = false,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  ...props
}) => {
  const onSetTime = val => {
    const date = value;
    const time = val.split(':');

    date.setHours(parseInt(time[0]), parseInt(time[1]));
    onChange(date);
  };

  const getTime = date => {
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${hours}:${minutes}`;
  };

  const getInputFromType = type => {
    switch (type) {
      case 'select':
        return (
          <select
            onChange={e => onChange(e.target.value)}
            value={value}
            {...props}>
            {(options.length ? options : fills[fill] || []).map(
              (option, key) => {
                return (
                  <option key={key} value={option.value}>
                    {option.option}
                  </option>
                );
              },
            )}
          </select>
        );
      case 'time':
        return (
          <select
            onChange={e => onSetTime(e.target.value)}
            value={getTime(value)}
            disabled={disabled}
            {...props}>
            {fills['hours'].map((option, key) => {
              return (
                <option key={key} value={option.value}>
                  {option.option}
                </option>
              );
            })}
          </select>
        );
      case 'rich-text':
        return (
          <Suspense
            fallback={
              <Skeleton startColor="gray.50" endColor="gray.100" h="200px" />
            }>
            <ReactQuill
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline'],
                  [{ list: 'bullet' }],
                  ['link', 'image', 'video'],
                  ['clean'],
                ],
              }}
              defaultValue={{ ops: [] }}
              value={value || { ops: [] }}
              formats={[
                'bold',
                'italic',
                'underline',
                'bullet',
                'link',
                'image',
                'video',
              ]}
              onChange={(content, delta, source, editor) =>
                onChange(editor.getContents())
              }
            />
          </Suspense>
        );
      case 'mask':
        return (
          <InputMask
            mask={format}
            type={customType}
            value={value}
            maskChar=" "
            alwaysShowMask={false}
            onChange={e => onChange(e.target.value)}
            {...props}
          />
        );
      case 'textarea':
        return (
          <textarea
            type={type}
            disabled={disabled}
            onChange={e => onChange(e.target.value)}
            value={value}
            {...props}
          />
        );
      case 'checkbox':
        return (
          <input
            type={type}
            disabled={disabled}
            onChange={e => onChange(e.target.checked)}
            value={value}
            {...props}
          />
        );
      default:
        return (
          <input
            type={type}
            disabled={disabled}
            onChange={e => onChange(e.target.value)}
            value={value}
            {...props}
          />
        );
    }
  };

  return (
    <div className={`input ${wrong && 'wrong'} ${type}`}>
      {!hideLabel && <label>{label}</label>}
      {getInputFromType(type)}
    </div>
  );
};

export default Input;
