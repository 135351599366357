import { create } from 'zustand';

interface ColaboratorStore {
  isInEditionMode: boolean;
  setIsInEditionMode: (isOpen: boolean) => void;

  isBluredMode: boolean;
  setIsBluredMode: (mode: boolean) => void;
}

export const colaboratorStore = create<ColaboratorStore>(set => ({
  isInEditionMode: false,
  setIsBluredMode: mode => {
    set({ isBluredMode: mode });
  },
  isBluredMode: false,
  setIsInEditionMode: (isInEdition: boolean) =>
    set({ isInEditionMode: isInEdition, isBluredMode: isInEdition }),
}));
