import React from 'react';

const useDetectClickOutside = ({ onTriggered, enabled }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleOutsideClick = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onTriggered?.(event);
      }
    };

    if (enabled) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      if (enabled) {
        document.removeEventListener('click', handleOutsideClick);
      }
    };
  }, [enabled, onTriggered]);

  return ref;
};

export default useDetectClickOutside;
