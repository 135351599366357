import { EventManagementLayout } from 'src/Layouts/EventManagementLayout';

import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@chakra-ui/layout';
import { EllipsisLoading } from 'src/components/loadings';

const RoutesLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "EventManagementRoutes" */
      'src/Pages/EventManagement/RoutesLazy'
    ),
);

export default function EventManagementRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<EventManagementLayout />}>
        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%,-50%)">
                  <EllipsisLoading color="brand.500" />
                </Box>
              }>
              <RoutesLazy />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
