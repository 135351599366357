const breakpoints = {
  sm: '30em', //480px
  md: '50em', //800px
  lg: '62em', //992px
  gl: '62.5em', //1000px
  xl: '80em', //1280px
  '2xl': '96em', //1536px
};

// base =>do 0 ate o sm;
export default breakpoints;
