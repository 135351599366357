import api, { userApi } from 'src/api';
import {
  GetEventInfoRequest,
  PostSellOpenRequest,
  PostSellOpenResponse,
  PostSellCloseRequest,
  PostEventRequest,
  PutSellEditRequest,
  PutSellEditResponse,
  PutSellBuyRequest,
  PutSellBuyResponse,
  PutSellPayRequest,
  PutSellPayResponse,
} from 'src/types/api';
import { EventPublicInfo } from 'src/types/dto';

export const getEvent = ({ eventId, params }: GetEventInfoRequest) =>
  api.get<EventPublicInfo>(`eventinfo/${eventId}`, { params }).then(res => {
    return res.data;
  });

export const postSellOpen = ({ payload, params }: PostSellOpenRequest) =>
  userApi
    .post<PostSellOpenResponse>('sell/open', payload, { params })
    .then(res => res.data);

export const postSellClose = ({ sellId }: PostSellCloseRequest) =>
  userApi.post<void>(`sell/${sellId}/close`).then(res => res.data);

export const postEventMessage = ({ eventId, params }: PostEventRequest) =>
  userApi
    .post<void>(`event/${eventId}?message=${params.message}`, { params })
    .then(res => res.data);

export const putSellEdit = ({ sellId, payload, params }: PutSellEditRequest) =>
  userApi
    .put<PutSellEditResponse[]>(`sell/${sellId}/edit`, payload, { params })
    .then(res => res.data);

export const putSellBuy = ({ sellId, payload, params }: PutSellBuyRequest) =>
  userApi
    .put<PutSellBuyResponse>(`/sell/${sellId}/buy`, payload, { params })
    .then(res => res.data);

// export const putSellBuy = ({ sellId, payload, params }: PutSellBuyRequest) =>
//   new Promise<PutSellBuyResponse>(resolve =>
//     resolve({
//       taxCCPixDiff: 0.1,
//       feePricePix: 10,
//       requireDocument: true,
//       requirePhone: true,
//     }),
//   );

export const putSellPay = ({ sellId, payload, params }: PutSellPayRequest) =>
  userApi
    .put<PutSellPayResponse>(`/sell/${sellId}/pay`, payload, { params })
    .then(res => res.data);
