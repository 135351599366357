
import './styles.scss';

const Button = ({
  color,
  withBorder,
  fullWidth,
  type,
  mode,
  className,
  children,
  disabled = false,
  style = {},
  onClick = () => {},
  htmlType,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      className={[
        'button',
        color,
        withBorder && 'with-border',
        fullWidth && 'full-width',
        type === 'loading' ? 'loading-button' : undefined,
        mode === 'loading' ? 'loading' : undefined,
        className,
      ].join(' ')}
      disabled={disabled}
      style={style}
      type={htmlType}
      {...rest}>
      <div className="inner">{children}</div>
      <div className="loading-balls">
        <div className="ball"></div>
        <div className="ball"></div>
        <div className="ball"></div>
      </div>
    </button>
  );
};

export default Button;
