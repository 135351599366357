import React from 'react';
import { isFunction, __DEV__ } from '@chakra-ui/utils';
import {Alert} from '@reach/alert';
import { motion, useIsPresent } from 'framer-motion';

const toastMotionVariants = {
  initial: {
    top: '-50%',
  },
  animate: {
    top: 0,
    transition: {
      duration: 0.4,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    top: '-50%',
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 1, 1],
    },
  },
};

export const Toast = ({ message, duration = 3000, onRequestRemove }) => {
  const isPresent = useIsPresent();

  const close = React.useCallback(() => {
    if (isPresent) {
      onRequestRemove?.();
    }
  }, [isPresent, onRequestRemove]);

  React.useEffect(() => {
    const timeout = setTimeout(close, duration);
    return () => {
      clearTimeout(timeout);
    };
  }, [close, duration]);

  return (
    <motion.div
      layout
      variants={toastMotionVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="chakra-toast"
      style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 5500 }}>
      <Alert
        className="chakra-toast__inner"
        style={{ pointerEvents: 'auto' }}>
        {isFunction(message) ? message({ onClose: close }) : message}
      </Alert>
    </motion.div>
  );
};

if (__DEV__) {
  Toast.displayName = 'Toast';
}
