import React from 'react';
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  useTheme,
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Link,
} from '@chakra-ui/react';

import { Link as RouterLink, NavLink, NavLinkProps } from 'react-router-dom';

import { ReactComponent as Logo } from 'src/assets/new_uticket_logo.svg';

type SideMenuButtonProps = ButtonProps & Pick<NavLinkProps, 'to'>;

export const EventManagementTopBar: React.FC<FlexProps> = props => (
  <Flex
    px="4"
    top="0"
    left="0"
    right="0"
    bg="white"
    borderBottomColor="gray.200"
    borderBottomWidth="1px"
    align="center"
    justify="space-between"
    {...props}
  />
);

export const EventManagementTopBarLogo: React.FC = () => (
  <Link
    textAlign="center"
    _focus={{ boxShadow: 'none' }}
    as={RouterLink}
    to="/"
    aria-label="Ir para página inicial do Uticket"
    title="Uticket seu evento começa aqui"
    ml="2">
    <Logo height="24px" />
  </Link>
);

export const EventManagementIconButtonMenu: React.FC<
  IconButtonProps
> = props => <IconButton {...props} />;

export const EventManagementSideMenuButton: React.FC<
  SideMenuButtonProps
> = props => {
  const { colors } = useTheme();
  return (
    <Button
      h="10"
      flexShrink="0"
      textDecor="none"
      color="black"
      variant="ghost"
      justifyContent="flex-start"
      _hover={{ bg: 'gray.50' }}
      _active={{ bg: 'gray.100' }}
      as={NavLink}
      _activeLink={{
        backgroundColor: colors.brand[50],
        color: colors.brand[500],
      }}
      {...props}
    />
  );
};

const Content: React.FC<BoxProps> = props => (
  <Box overflow="hidden" {...props} />
);

const EventManagement = () => {
  // UPDATING TODO: ver oq isso faz e como reproduzir no novo router-dom v6
  // navigate.listen(() => {
  //   setSideMenuWidth(0);
  // });

  return (
    <Box h="100%">
      <Content
        w={{ base: 'none', lg: 'calc(100% - 250px)' }}
        mt="64px"></Content>
    </Box>
  );
};

export default EventManagement;
