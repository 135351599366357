
import { forwardRef } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { BaseFloatingLabelInputProps, FloatingLabelInput } from './FloatingLabelInput';



export const FloatingLabelInputNumberFormat = forwardRef<
  HTMLInputElement,
  NumberFormatProps<BaseFloatingLabelInputProps>
>((props: NumberFormatProps<BaseFloatingLabelInputProps>, ref) => {
  return (
    <NumberFormat
      customInput={FloatingLabelInput}
      getInputRef={ref}
      {...props}
    />
  );
});
