import { Button, Text } from '@chakra-ui/react';
import { usePrompt } from 'src/hooks';

const useYesNoPrompt = () => {
  const prompt = usePrompt();

  const openAsync = ({ body }) => {
    return prompt.openAsync({
      title: 'Confirmação',
      body,
      buttons: ({ focusableRef, onResolve, onClose }) => (
        <>
          <Button onClick={onResolve} rounded="full" colorScheme="blue">
            Sim
          </Button>
          <Button
            ml={2}
            ref={focusableRef}
            rounded="full"
            onClick={onClose}
            colorScheme="gray">
            <Text color="#000000">Não</Text>
          </Button>
        </>
      ),
    });
  };

  return { ...prompt, openAsync };
};

export default useYesNoPrompt;
