export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const Sections = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  RECOVERY: 'recovery',
  REGISTER: 'register',
  FORGET_PASSWORD: 'forgetPassword',
  EMAIL_SENT: 'emailSent',
};
