import {
  Flex,
  Text,
  ColorModeContext,
  ThemeProvider,
  useChakra,
  Icon,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { noop } from '@chakra-ui/utils';
import {
  IoInformationCircleOutline,
  IoAlertCircleOutline,
} from 'react-icons/io5';

import { toast } from './toast.class';

const STATUSES = {
  info: { icon: IoInformationCircleOutline },
  warning: { icon: IoAlertCircleOutline },
};

const Toast = ({ description, status = 'info' }) => {
  return (
    <Flex
      align="center"
      justify="center"
      p="4"
      bg="black"
      color="white"
      h="16"
      overflow="hidden">
      <Icon as={STATUSES[status].icon} boxSize="8" mr="4" />
      <Text
        w="1250px"
        maxW="calc(100vw - 40px)"
        fontSize={['xs', 'sm', 'md', 'md']}
        fontWeight="semibold">
        {description}
      </Text>
    </Flex>
  );
};

const defaults = {
  duration: 3000,
};

const defaultStandaloneParam = {
  theme: null,
  colorMode: 'light',
  toggleColorMode: noop,
  setColorMode: noop,
  defaultOptions: defaults,
};

export function createStandaloneToast({
  theme = defaultStandaloneParam.theme,
  colorMode = defaultStandaloneParam.colorMode,
  toggleColorMode = defaultStandaloneParam.toggleColorMode,
  setColorMode = defaultStandaloneParam.setColorMode,
} = defaultStandaloneParam) {
  const renderWithProviders = (props, options) => {
    return (
      <ThemeProvider theme={theme}>
        <ColorModeContext.Provider
          value={{ colorMode, setColorMode, toggleColorMode }}>
          <Toast {...props} {...options} />
        </ColorModeContext.Provider>
      </ThemeProvider>
    );
  };

  return options => {
    const Message = props => renderWithProviders(props, options);
    return toast.notify(Message, options);
  };
}

export function useToast() {
  const { theme, setColorMode, toggleColorMode, colorMode } = useChakra();
  return useMemo(
    () =>
      createStandaloneToast({
        theme,
        colorMode,
        setColorMode,
        toggleColorMode,
      }),
    [theme, setColorMode, toggleColorMode, colorMode],
  );
}

export default useToast;
