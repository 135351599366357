import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import MainMenu from 'src/legacy/Components/Menus/MainMenu';

import { Urls } from 'src/appConstants';
import { ReactComponent as Logo } from 'src/assets/new_uticket_logo.svg';
import { useFeatureFlag } from 'src/hooks';

import './styles.scss';
import { Button, IconButton, HStack } from '@chakra-ui/react';
import { IoMenu } from 'react-icons/io5';

const Header = () => {
  const navigate = useNavigate();
  const turnOnNewLandingPage = useFeatureFlag('site-landing-page');
  //const turnOnCreateEvent = useFeatureFlag('create-event');
  const [showMainMenu, setShowMainMenu] = React.useState(false);

  const navigateToLandingPage = () => {
    navigate(turnOnNewLandingPage ? '/solucoes' : Urls.LANDING_PAGE);
  };

  const navigateToEventNew = () => {
    navigate('/me/events/new');
  };

  const onOpenMainMenu = () => {
    setShowMainMenu(true);
  };

  const onCloseMainMenu = () => {
    setShowMainMenu(false);
  };

  // const navigateToTalkToConsultor = () => {
  //   navigate('/ajuda/falar-com-consultor');
  // };

  return (
    <header className="main-header">
      <div className="container header">
        <RouterLink
          to="/"
          aria-label="Ir para página inicial do Uticket"
          title="Uticket seu evento começa aqui">
          <Logo height="35px" />
        </RouterLink>
        <HStack float="right">
          {turnOnNewLandingPage && (
            <Button
              variant="ghost"
              color="brand.500"
              rounded="full"
              display={['none', 'none', 'inline-flex', 'inline-flex']}
              onClick={navigateToLandingPage}>
              Organizadores
            </Button>
          )}
          {/* {turnOnCreateEvent && (
            <Button
              variant="outline"
              rounded="full"
              display={['none', 'none', 'inline-flex', 'inline-flex']}
              onClick={navigateToEventNew}>
              Publicar evento
            </Button>
          )} */}
          <Button
            variant="ghost"
            rounded="full"
            fontWeight="bold"
            color="black"
            fontSize="sm"
            display="inline-flex"
            backgroundColor="gray.50"
            onClick={navigateToEventNew}>
            Criar Evento
          </Button>
          <IconButton
            icon={<IoMenu size="30" />}
            variant="ghost"
            color="black"
            rounded="full"
            onClick={onOpenMainMenu}
            aria-label="Abrir menu lateral"
          />
        </HStack>
      </div>
      <MainMenu show={showMainMenu} onDismiss={onCloseMainMenu} />
    </header>
  );
};

export default Header;
