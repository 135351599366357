import React from 'react';
import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

type Status = 'closed' | 'success' | 'error';

type Response<Data> = { data: Data; status: Status };

export type ModalProps<Props, Data = null, Error = void> = Props & {
  onClose: () => void;
  onResolve: (response?: Response<Data>) => void;
  onReject: (error: Error) => void;
};

type StoreModal<Props, Data, Error> = {
  id: string;
  component: React.FC<ModalProps<Props, Data, Error>>;
  props: Props;
  onResolve: (response: Response<Data>) => void;
  onReject: (error: Error) => void;
};

type ModalStoreProps<Props, Data = null, Error = void> = {
  modals: StoreModal<Props, Data, Error>[];
  openAsync: (modal: {
    component: React.FC<ModalProps<Props, Data, Error>>;
    props: Props;
  }) => Promise<Response<Data>>;
  close: (id: string) => void;
};

const useModalStore = create<ModalStoreProps<{}>>(set => ({
  modals: [],
  openAsync: options => {
    return new Promise((onResolve, onReject) => {
      const id = uuidv4();
      set(prev => ({
        modals: [...prev.modals, { id, onResolve, onReject, ...options }],
      }));
    });
  },
  close: id =>
    set(prev => ({ modals: prev.modals.filter(modal => modal.id !== id) })),
}));

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const { modals, close } = useModalStore();

  const onClose = (id: string) => {
    close(id);
  };

  return (
    <>
      {children}
      {modals.map(modal => {
        const ModalComponent = modal.component;
        const { onResolve, onReject, props } = modal;
        return (
          <React.Fragment key={modal.id}>
            <ModalComponent
              {...props}
              onClose={() => {
                onResolve({ data: null, status: 'closed' });
                onClose(modal.id);
              }}
              onResolve={(data: any) => {
                onResolve({ data, status: 'success' });
                onClose(modal.id);
              }}
              onReject={(error: any) => {
                onReject(error);
                onClose(modal.id);
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export const useModal = <Props, Data = null, Error = void>(
  component: React.FC<ModalProps<Props, Data, Error>>,
) => {
  const { openAsync: storeOpenAsync } = useModalStore();

  const openAsync = React.useCallback(
    (props: Props): Promise<Response<Data>> => {
      // TODO: find out how to better type zustand store with generics
      // @ts-ignore
      return storeOpenAsync({ component, props });
    },
    [component, storeOpenAsync],
  );

  return React.useMemo(() => ({ openAsync }), [openAsync]);
};
