import { Button } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icon';
import { ReactNode, useState } from 'react';
import { authApi } from 'src/api';
import { EllipsisLoading } from 'src/components/loadings';
import { BiLogoGoogle } from 'react-icons/bi';
import { Box, Text } from '@chakra-ui/layout';

const getConsentOAuthUrl = () =>
  authApi
    .get('oauth-signin', {
      params: { p: 'google' },
      headers: { 'X-Referer': window.location.href },
    })
    .then(res => res.data);

type Props = { children: ReactNode };
const GoogleButton = ({ children }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const userAgent = navigator.userAgent;

  const signIn = async () => {
    if (userAgent.includes('Instagram')) {
      setError(
        'Login com Google não funciona dentro do Instagram. Utilize o Chrome ou Safari.',
      );
    } else {
      try {
        setLoading(true);
        const consentUrl = await getConsentOAuthUrl();

        window.location.replace(consentUrl);
      } catch (er) {
        setLoading(false);
      }
    }
  };

  return (
    <Box>
      <Button
        colorScheme="brand"
        rounded="full"
        w="full"
        isLoading={isLoading}
        spinner={<EllipsisLoading color="white" />}
        onClick={signIn}>
        <Icon as={BiLogoGoogle} boxSize={6} mr="2" />

        {children}
      </Button>
      {error && (
        <Text mt={2} textAlign="center" fontSize={18}>
          {error}
        </Text>
      )}
    </Box>
  );
};

export { GoogleButton };
