import { BasicEvent } from 'src/types/dto';
import { userApi } from '.';
import api from 'src/api';

type ProducerPostProps = {
  banner: File | string | null;
  logo: File | null;
  name: string;
  slug: string;
  description: string;
};

export const postNewProducer = async (
  userID: string,
  payload: ProducerPostProps,
) => {
  const data = new FormData();
  for (const [index, item] of Object.entries(payload)) {
    if (payload.banner) {
      if (item === payload.banner) {
        // TODO: Entender pq a v5 do typescript reclama disso aqui.
        // @ts-ignore
        data.append('banner', payload.banner, (payload.banner as File).name);
        continue;
      }
    }
    if (payload.logo) {
      if (item === payload.logo) {
        data.append('logo', payload.logo, payload.logo.name);
        continue;
      }
    }
    data.append(index, item!);
  }

  const response = await userApi.post(`organizerpage/${userID}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
};

type ProducerPutProps = {
  banner?: File | null;
  logo?: File | null;
  name?: string;
  slug?: string;
  description?: string;
};

export const editProducer = async (
  userID: string,
  payload: ProducerPutProps,
) => {
  const data = new FormData();
  for (const [index, item] of Object.entries(payload)) {
    if (payload.banner) {
      if (item === payload.banner) {
        data.append('banner', payload.banner, (payload.banner as File).name);
        continue;
      }
    }
    if (payload.logo) {
      if (item === payload.logo) {
        data.append('logo', payload.logo, payload.logo.name);
        continue;
      }
    }
    data.append(index, item!);
  }
  const response = await userApi.put(`organizerpage/${userID}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
};
export type ProfileData = {
  id: string;
  userId: string;
  name: string;
  slug: string;
  description?: string;
  urlsSocial: {
    instagram: string;
    whatsapp: string;
    twitter: string;
    tiktok: string;
  };
  bannerOrganizerPage?: string;
  logoOrganizerPage: string;
};
export const getProducerBySlug = async (slug: string): Promise<ProfileData> => {
  const response = await userApi.get(`organizerpage/?slug=${slug}`);
  return response.data;
};

export const getProducerById = async (userId: string): Promise<ProfileData> => {
  const response = await userApi.get(`organizerpage/?userId=${userId}`);
  return response.data;
};

export const checkSlugExists = async (slug: string) => {
  const response = await userApi.get(`organizerpage/checkinExists//${slug}`);
  return response;
};

export const getProducerEvents = async (
  producerId: string,
): Promise<BasicEvent[]> => {
  const response = await api.get(`/event/user/${producerId}`);

  const data = response.data;

  return data;
};
