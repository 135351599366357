import {
  Updater,
  UseBaseQueryOptions,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
// import { Updater } from '@tanstack/react-query';

// export const useUpdaterQuery = <
//   TQueryFnData = unknown,
//   TError = unknown,
//   TData = TQueryFnData,
//   TQueryKey extends QueryKey = QueryKey,
// >(
//   queryKey: TQueryKey,
//   queryFn: QueryFunction<TQueryFnData, TQueryKey>,
//   options?: Omit<
//     UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
//     'queryKey' | 'queryFn'
//   >,
// ) => {
//   const queryClient = useQueryClient();

  
//   const query = useQuery<TQueryFnData, TError, TData, TQueryKey>({
//     queryKey,
//     queryFn,
//     ...options,
//   });
  
  
//  console.log([...queryKey])
//   if (!options?.enabled) {
//     console.log("entrou", options?.enabled)
//     queryClient.setQueryData([...queryKey], null);
//   }

//   // TODO: Verificar a tipagem do updater
//   const update = (updater: any) => {
//     // O setQueryClient nao atualiza os dados de o valor recebido for undefined.
//     if (updater === undefined) updater = null;

//     queryClient.setQueryData(queryKey, updater);
//   };

//   return { ...query, update };
// };

type Props<T> = {
  queryFn: () => Promise<T>
} & UseBaseQueryOptions

export const useUpdaterQuery = <T>({ queryKey, queryFn, ...options }: Props<T>) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey,
    queryFn,
    ...options,
  }) as UseQueryResult & {data: T};

  
  const update = (data: T | null | undefined | Updater<T,T>) => {
    if (!data) {
      queryClient.setQueryData(queryKey, null);
      return;
    }

    queryClient.setQueryData(queryKey, data);
  };

  return { ...query, update };
};
