import { Outlet, useLocation } from 'react-router-dom';
import Header from 'src/legacy/Components/Bars/Header';

const ROUTES_TO_NOT_DISPLAY_HEADER = ['/checkout'];

export const RoutesWithHeader = () => {
  const pathname = useLocation().pathname;

  const shouldDisplayHeader = !ROUTES_TO_NOT_DISPLAY_HEADER.some(route =>
    pathname.includes(route),
  );
  return (
    <>
      {shouldDisplayHeader && <Header />}
      <Outlet />
    </>
  );
};
