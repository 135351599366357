import Button from './button';
import Checkbox from './checkbox';
import CloseButton from './close-button';
import Drawer from './drawer';
import Input from './input';
import Link from './link';
import Menu from './menu';
import Modal from './modal';
import Popover from './popover';
import Progress from './progress';
import Radio from './radio';
import Select from './select';
import Skeleton from './skeleton';
import Slider from './slider';
import Switch from './switch';
import Table from './table';
import Textarea from './textarea';
import Tooltip from './tooltip';

const components = {
  Button,
  Checkbox,
  CloseButton,
  Drawer,
  Input,
  Link,
  Menu,
  Modal,
  Popover,
  Progress,
  Radio,
  Select,
  Skeleton,
  Slider,
  Switch,
  Table,
  Textarea,
  Tooltip,
};

export default components;
