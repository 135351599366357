import { Input, InputProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import './remove-autocomplete-bg.css';

export type BaseFloatingLabelInputProps = InputProps;

export const FloatingLabelInput = forwardRef<
  HTMLInputElement,
  BaseFloatingLabelInputProps
>(({ placeholder, ...props }, ref) => {
  return (
    <Input
      ref={ref}
      h="full"
      border="none"
      placeholder=" "
      w="full"
      borderRadius="lg"
      pt="4"
      px=".74rem"
      {...props}
    />
  );
});
