const borders = {
  none: 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '3px': '3px solid',
  '4px': '4px solid',
  '5px': '5px solid',
  '8px': '8px solid',
};

export default borders;
