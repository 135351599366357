import { Box, keyframes } from '@chakra-ui/react';

const scale = keyframes({
  '0%': {
    transform: 'scale(1)',
  },
  '50%': {
    transform: 'scale(1.5)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

const BallScale = ({ size, bg, delay, ...rest }) => {
  return (
    <Box
      __css={{
        boxSize: size,
        rounded: 'full',
        bg,
        animationName: 'loading-ball',
        animation: `${scale} 2s linear ${delay} infinite`,
      }}
      {...rest}
    />
  );
};

export const EllipsisLoading = ({ size = 2, color = 'white', spacing = 3 }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      fontSize="1em"
      lineHeight="normal">
      <BallScale delay="0s" {...{ size }} bg={color} />
      <BallScale delay="0.3s" {...{ size }} bg={color} ml={spacing} />
      <BallScale delay="0.6s" {...{ size }} bg={color} ml={spacing} />
    </Box>
  );
};
