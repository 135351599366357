import * as React from 'react';
import { AnimatePresence } from 'framer-motion';

import { Toast } from './toast';

export class ToastManager extends React.Component {
  state = {
    toast: null,
  };

  constructor(props) {
    super(props);

    const methods = {
      notify: this.notify,
    };

    props.notify(methods);
  }

  createToast = (message, options) => {
    return {
      message,
      status: options.status,
      duration: options.duration,
      onRequestRemove: () => this.removeToast(),
    };
  };

  notify = (message, options) => {
    const toast = this.createToast(message, options);
    this.setState({ toast });
  };

  removeToast = () => {
    this.setState({ toast: null });
  };

  render() {
    const { toast } = this.state;
    return (
      <AnimatePresence initial={false}>
        {!!toast && <Toast {...toast} />}
      </AnimatePresence>
    );
  }
}
