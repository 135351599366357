import {create} from 'zustand';
import { persist } from 'zustand/middleware';

import { Storages } from 'src/appConstants';

const useUrlsStore = create()(
  persist(
    (set, get) => ({
      wirecard: '/',
      checkpointWirecard: () =>
        set({ ...get(), wirecard: window.location.href }),
    }),
    { name: Storages.URLS },
  ),
);

export default useUrlsStore;
