import React from 'react';

import throttle from 'lodash/throttle';

const getDeviceConfig = (width: number) => {
  if (width < 320) {
    return 'xs';
  } else if (width >= 320 && width < 800) {
    return 'sm';
  } else if (width >= 800 && width < 1024) {
    return 'md';
  }
  return 'lg';
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = React.useState(() =>
    getDeviceConfig(window.innerWidth),
  );

  React.useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};
export default useBreakpoint;
