import React, { useState } from 'react';
import classNames from 'classnames';
import {
  ErrorMessage,
  FloatingLabel,
  FloatingLabelContainer,
  FloatingLabelInput,
} from 'src/components/Inputs/FloatingLabel';
import { Button, Box, Heading, FormControl, Icon } from '@chakra-ui/react';
import { EllipsisLoading } from 'src/components/loadings';
import { Sections } from 'src/legacy/Components/auth/_constants';
import { postPasswordRecovery } from 'src/legacy/Components/auth/_api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'src/utils/form';
import { BsEnvelope } from 'react-icons/bs';

type RecoveryFormProps = {
  navigate: any;
};

const yupSchema = yup.object({
  email: yup.string().required('Preencha o email').email('Email inválido'),
});

type FormSchema = {
  email: string;
};
const RecoveryForm: React.FC<RecoveryFormProps> = ({ navigate }) => {
  const [section, setSection] = useState(Sections.FORGET_PASSWORD);

  const [error, setError] = useState<string | null>(null);

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormSchema>({
    resolver: yupResolver(yupSchema),
  });

  const { onChange: onEmailChange, ...registerEmail } = register('email');

  const onSubmit = handleSubmit(async data => {
    try {
      await postPasswordRecovery(data.email);
      setSection(Sections.EMAIL_SENT);
    } catch (er) {
      setError('Usuário não cadastrado');
    }
  });

  return (
    <Box className="layout-steps">
      <Box
        className={classNames('auth-section', 'layout-step', {
          current: section === Sections.FORGET_PASSWORD,
        })}>
        <Heading mt="0">Esqueceu a senha</Heading>
        <Box as="span" textColor="gray.400" className="instructions">
          Digite o email cadastrado no campo abaixo para receber instruções para
          recuperação de conta.
        </Box>
        <Box as="form" noValidate {...{ onSubmit }}>
          <FormControl>
            <FloatingLabelContainer mt="4">
              <FloatingLabelInput
                {...registerEmail}
                onChange={e => {
                  onEmailChange(e);
                  setError(null);
                }}
                type="email"
              />
              <FloatingLabel>Email</FloatingLabel>
            </FloatingLabelContainer>
            <ErrorMessage>{errors.email?.message || error}</ErrorMessage>
          </FormControl>

          <Button
            type="submit"
            colorScheme="brand"
            rounded="full"
            w="full"
            mt="4"
            isLoading={isSubmitting}
            spinner={<EllipsisLoading color="white" />}>
            Recuperar conta
          </Button>
        </Box>

        <Button
          w="full"
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
          color="brand.500"
          onClick={() => navigate('login')}>
          Lembrei, ir para login
        </Button>
      </Box>
      <Box
        className={classNames('auth-section', 'layout-step', {
          current: section === Sections.EMAIL_SENT,
        })}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          alignItems: 'center',
        }}>
        <Icon as={BsEnvelope} width={12} height={12} />
        <Heading>Verifique seu e-mail</Heading>
        <Box as="span" className="instructions" style={{ textAlign: 'center' }}>
          Nós te enviamos instruções sobre como recuperar a sua conta, não se
          esqueça de conferir a caixa de spam
        </Box>
      </Box>
    </Box>
  );
};

export default RecoveryForm;
