import { IconButton, Box, Flex, CloseButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineShop, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FiArchive, FiBookOpen, FiPieChart } from 'react-icons/fi';
import {
  HiOutlineClipboardList,
  HiOutlinePuzzle,
  HiOutlineUserGroup,
} from 'react-icons/hi';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { IoMenu, IoPeopleOutline, IoTicketOutline } from 'react-icons/io5';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { BodyBluredOverlay } from 'src/components/BodyBluredOverlay';
import { useFeatureFlag } from 'src/hooks';
import { useUserStore } from 'src/hooks/stores';
import { getEvent } from 'src/Pages/Event/api';
import {
  EventManagementSideMenuButton,
  EventManagementTopBar,
  EventManagementTopBarLogo,
} from 'src/Pages/EventManagement';
import { colaboratorStore } from 'src/Pages/EventManagement/CollaboratorsNew/store';

const headerHeight = '64px';

export const EventManagementLayout = () => {
  const { id } = useUserStore();

  const turnOnIntegrations = useFeatureFlag('integrations');
  const turnOnStaff = useFeatureFlag('staff-flag');

  const navigate = useNavigate();
  const { eventID, userLevel } = useParams();
  const isAdmin = userLevel === 'admin';

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { setIsBluredMode } = colaboratorStore();

  const navigateToHome = () => {
    navigate('/me/events', { replace: true });
  };

  useEffect(() => {
    const checkUser = async () => {
      await getEvent({
        eventId: eventID!,
        params: { ref: 'val' },
      }).then(res => {
        if (res.userId !== id) {
          navigate(`/staff/event/${eventID}/dashboard`, { replace: true });
        }
      });
    };
    if (turnOnStaff) {
      if (userLevel === 'admin') {
        checkUser();
      } else if (userLevel !== 'staff') {
        navigate(`/`, { replace: true });
      }
    }
  }, [navigate, id, eventID, turnOnStaff, userLevel]);

  return (
    <>
      <Box>
        <BodyBluredOverlay
          handleCloseModal={() => {
            setIsMobileMenuOpen(false);
            setIsBluredMode(false);
          }}
          isOpen={isMobileMenuOpen}
          zIndex={9}
        />
        <EventManagementTopBar h={headerHeight} position="fixed" zIndex={'9'}>
          <Flex dir="row" align="center">
            <IconButton
              display={{ base: 'flex', lg: 'none' }}
              alignItems="center"
              justifyContent="center"
              onClick={() => setIsMobileMenuOpen(prev => !prev)}
              icon={<IoMenu size="24" />}
              variant="ghost"
              color="black"
              rounded="full"
              aria-label="Abrir menu de navegação"
            />
            <EventManagementTopBarLogo />
          </Flex>
          <Flex align="center">
            <CloseButton
              onClick={navigateToHome}
              color="black"
              textColor="black"
            />
          </Flex>
        </EventManagementTopBar>

        <Box display="flex">
          <Box
            w="250px"
            position="fixed"
            left={{ base: isMobileMenuOpen ? '0' : '-100%', lg: '0' }}
            top={{ base: '0', lg: headerHeight }}
            bottom="0"
            transition="left .2s linear"
            zIndex="docked"
            p="4"
            bg="white"
            display="flex"
            flexDir="column"
            flexShrink="unset"
            overflow="auto"
            gap="2"
            boxShadow={{ base: 'md', lg: 'none' }}>
            <EventManagementSideMenuButton
              onClick={() => setIsMobileMenuOpen(false)}
              leftIcon={<FiPieChart />}
              to="dashboard">
              Dashboard
            </EventManagementSideMenuButton>
            <EventManagementSideMenuButton
              onClick={() => setIsMobileMenuOpen(false)}
              leftIcon={<FiBookOpen />}
              to="reports">
              Relatórios
            </EventManagementSideMenuButton>
            <EventManagementSideMenuButton
              onClick={() => setIsMobileMenuOpen(false)}
              leftIcon={<AiOutlineShop />}
              to="sales-origins">
              Canais de Venda
            </EventManagementSideMenuButton>
            <EventManagementSideMenuButton
              onClick={() => setIsMobileMenuOpen(false)}
              leftIcon={<IoPeopleOutline />}
              to="sold-tickets">
              Ingressos Vendidos
            </EventManagementSideMenuButton>

            <EventManagementSideMenuButton
              onClick={() => setIsMobileMenuOpen(false)}
              leftIcon={<IoMdCheckboxOutline />}
              to="checkin">
              Checkin
            </EventManagementSideMenuButton>

            <EventManagementSideMenuButton
              onClick={() => setIsMobileMenuOpen(false)}
              leftIcon={<IoTicketOutline />}
              to="coupons">
              Cupons
            </EventManagementSideMenuButton>
            <EventManagementSideMenuButton
              onClick={() => setIsMobileMenuOpen(false)}
              leftIcon={<FiArchive />}
              to="promoters">
              Promoters
            </EventManagementSideMenuButton>
            <EventManagementSideMenuButton
              onClick={() => setIsMobileMenuOpen(false)}
              leftIcon={<HiOutlineClipboardList />}
              to="listas">
              Lista de Convidados
            </EventManagementSideMenuButton>
            {isAdmin && (
              <EventManagementSideMenuButton
                onClick={() => setIsMobileMenuOpen(false)}
                leftIcon={<HiOutlineUserGroup />}
                to="affiliates">
                Afiliados
              </EventManagementSideMenuButton>
            )}
            {turnOnStaff && isAdmin && (
              <EventManagementSideMenuButton
                onClick={() => setIsMobileMenuOpen(false)}
                leftIcon={<AiOutlineUsergroupAdd />}
                to="collaborators">
                Colaboradores
              </EventManagementSideMenuButton>
            )}
            {turnOnIntegrations && (
              <EventManagementSideMenuButton
                onClick={() => setIsMobileMenuOpen(false)}
                leftIcon={<HiOutlinePuzzle />}
                to="integrations">
                Integrações
              </EventManagementSideMenuButton>
            )}
          </Box>

          <Box ml={{ base: '0', lg: '250px' }} pt={headerHeight} w="full">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
