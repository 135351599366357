export const Env = process.env.REACT_APP_ENV;

export const PR_NUMBER = process.env.REACT_APP_PR_NUMBER;

export const Integrations = {
  GADS_ID: process.env.REACT_APP_GADS_ID,
  GA_ID: process.env.REACT_APP_GA_ID,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  PIXEL_ID: process.env.REACT_APP_PIXEL_ID,
  PIXEL_ID_CABOFOLIA: '761210921252624',
  PIXEL_ID_BAROES: '345623110383859',
  PIXEL_ID_JOGOS: '791323710988884',
  PIXEL_ID_FERRUGEM: '254181826623761',
  PIXEL_ID_FEIJOADA_BARROCO: '72692279782758',
  PIXEL_ID_CABOFOLIA_REAL: '2150474991777484',
  PIXEL_ID_BRASIL_EMPREENDEDOR: '3153201348331150',
  PIXEL_ID_303_STAGE_NEXT_LEVEL: '751415429519833',
  PIXEL_ID_JHORDAN_MATHEUS: '3452844071654282',
  TAWK_TO_ID: process.env.REACT_APP_TAWK_TO_ID,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  GOOGLE_AUTH_ID: process.env.REACT_APP_GOOGLE_AUTH_ID,
  INSPECTLET_ID: process.env.REACT_APP_INSPECTLET_ID,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
};

export const Urls = {
  BASE: new URL(window.location.href).origin,
  API: process.env.REACT_APP_API_URL,
  AUTH_API: process.env.REACT_APP_AUTH_API_URL,
  BFF_API: process.env.REACT_APP_BFF_API_URL,
  EVENT_API: process.env.REACT_APP_EVENT_API_URL,
  CHECKOUT_API: process.env.REACT_APP_CHECKOUT_API_URL,
  PAGARME_API: process.env.REACT_APP_PAGARME_API_URL,
  IMG: process.env.REACT_APP_IMG_URL,
  TICKET: process.env.REACT_APP_TICKET_URL,
  LANDING_PAGE: process.env.REACT_APP_LANDING_PAGE_URL,
};

const storagePrefix = '@uticket';

export const Storages = {
  USER: `${storagePrefix}:user`,
  URLS: `${storagePrefix}:urls`,
  CLIENT_UUID: 'X-Correlation-ID',
  COOKIE_CONSENT: `${storagePrefix}:cookie-consent`,
  CHECKOUT: `${storagePrefix}:checkout`,
  CHECKOUT_FORM_VALUES: `${storagePrefix}:checkout-form-values`,
  EVENT_DRAFT: `${storagePrefix}:event-draft`,
};
