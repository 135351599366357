import { Icon, InputProps, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { PiEyeBold, PiEyeClosedBold } from 'react-icons/pi';
import {
  FloatingLabel,
  FloatingLabelContainerWithAddon,
  FloatingLabelInput,
} from 'src/components/Inputs/FloatingLabel';
import { forwardRef } from 'react';

type PasswordInputProps = InputProps & {
  label?: string;
};

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ label = 'Senha', ...inputProps }, ref) => {
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);

    const props = { ...inputProps, ref };
    return (
      <FloatingLabelContainerWithAddon
        addonPositon="right"
        addon={
          <Button
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            onClick={() => setIsPasswordOpen(prev => !prev)}>
            <Icon
              as={isPasswordOpen ? PiEyeBold : PiEyeClosedBold}
              color="gray.700"
              boxSize="5"
              cursor="pointer"
            />
          </Button>
        }>
        <FloatingLabelInput
          type={isPasswordOpen ? 'text' : 'password'}
          {...props}
        />
        <FloatingLabel>{label}</FloatingLabel>
      </FloatingLabelContainerWithAddon>
    );
  },
);
