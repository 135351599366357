import axios, { AxiosRequestConfig } from 'axios';
import { Env, Urls } from 'src/appConstants';

type MockSigninPayload = {
  email: string;
  password: string;
};

type MockSigninResponse = {
  creation: string;
  email: string;
  expiration: string;
  id: number;
  lastAccess: string;
  name: string;
  token: string;
};

type MockOAuthSigninConfig = {
  headers: { 'X-Referer': string };
};

type MockAxiosResponse<T> = {
  data: T;
};

type MockAccount = {
  [key: string]: number;
};

const mockAccounts: MockAccount = {
  'organizador@uticket.com.br': 1,
  'organizador_pos@uticket.com.br': 2,
  'cliente@uticket.com.br': 3,
  'promoter@uticket.com.br': 4,
};

const devAuth = {
  post: (
    _url: string,
    data: MockSigninPayload,
    _config: AxiosRequestConfig,
  ): Promise<MockAxiosResponse<MockSigninResponse>> =>
    new Promise(resolve =>
      resolve({
        data: {
          creation: new Date().toISOString(),
          email: data?.email ?? '',
          expiration: new Date().toISOString(),
          id: mockAccounts[data?.email] ?? 0,
          lastAccess: new Date().toISOString(),
          name: data?.email?.split('@')[0]?.toUpperCase() ?? '',
          token: 'devtest',
        },
      }),
    ),
  get: (
    _url: string,
    config: MockOAuthSigninConfig,
  ): Promise<MockAxiosResponse<string>> =>
    new Promise(resolve => resolve({ data: config.headers['X-Referer'] })),
};

const prodAuth = axios.create({
  baseURL: Urls.AUTH_API,
});

export default Env === 'development' ? devAuth : prodAuth;
