import { Box, BoxProps, Text } from '@chakra-ui/react';
import { addMonths, isBefore } from 'date-fns';

type Props = BoxProps & {
  createdAt: Date;
};

export const NewFeatureBadge = ({ createdAt, ...props }: Props) => {
  const amountOfMonthsToBeValid = 3;

  const today = new Date();
  const isValid = isBefore(
    today,
    addMonths(createdAt, amountOfMonthsToBeValid),
  );

  return (
    <>
      {isValid && (
        <Box
          px="2"
          py="1"
          bg="#f33e43"
          rounded="full"
          textColor="white"
          fontSize=".6875rem"
          fontWeight="bold"
          {...props}>
          <Text>NOVO</Text>
        </Box>
      )}
    </>
  );
};
