import React from 'react';

type TooltipEvents = {
  onTouchStart: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (event: React.MouseEvent) => void;
};

type UseTooltipEvents = [isOpen: boolean, events: TooltipEvents];

const useTooltipEvents = (): UseTooltipEvents => {
  const [isOpen, setOpen] = React.useState(false);
  const isMobileRef = React.useRef(false);

  const onTouchStart = () => {
    isMobileRef.current = true;
  };

  const onMouseEnter = () => {
    if (!isMobileRef.current) {
      setOpen(true);
    }
  };

  const onMouseLeave = () => {
    isMobileRef.current = false;
    setOpen(false);
  };

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(prev => !prev);
  };

  return [isOpen, { onTouchStart, onMouseEnter, onMouseLeave, onClick }];
};

export default useTooltipEvents;
