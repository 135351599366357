import { useQuery } from '@tanstack/react-query';

import api from 'src/api';

const getFeatureFlags = () => api.get('featureflag').then(res => res.data);

export const useFeatureFlagsQuery = () => {
  return useQuery({
    queryKey: ['featureflag'],
    queryFn: getFeatureFlags,
    staleTime: Infinity,
  });
};
