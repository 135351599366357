import React from 'react';

const useDisclosure = (initialState = false) => {
  const [isOpen, setOpen] = React.useState(initialState);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onToggle = () => {
    setOpen(prev => !prev);
  };

  return { isOpen, onOpen, onClose, onToggle };
};

export default useDisclosure;
