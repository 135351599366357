const googleAds = propertyId => {
  if (!window) {
    throw new Error('DOM is unavailable');
  }

  const GAds = document.getElementById(propertyId);
  if (!!GAds) {
    // Prevent TawkTo to create root script if it already exists
    return window.dataLayer;
  } else {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', propertyId);

    const script = document.createElement('script');
    script.id = propertyId;
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + propertyId;
    script.setAttribute('crossorigin', '*');

    const first_script_tag = document.getElementsByTagName('script')[0];
    if (!first_script_tag || !first_script_tag.parentNode) {
      throw new Error('DOM is unavailable');
    }

    first_script_tag.parentNode.insertBefore(script, first_script_tag);
  }
};

export default googleAds;
