import axios from 'axios';
import flow from 'lodash/flow';

import { Urls } from 'src/appConstants';

import {
  requestFullfilledAuthHeader,
  requestFullfilledCustomHeaders,
  responseArrayBuffer,
  responseBlob,
  responseFullfilled,
  responseRejected,
  responseRejectedNotFound,
} from './utils';

const axiosConfig = {
  baseURL: Urls.API,
};

const instance = axios.create(axiosConfig);

export const userApi = axios.create(axiosConfig);

export const bffApi = axios.create({
  baseURL: Urls.BFF_API,
});

export const fileApi = axios.create({
  ...axiosConfig,
  responseType: 'arraybuffer',
});

userApi.interceptors.request.use(
  flow([requestFullfilledAuthHeader, requestFullfilledCustomHeaders]),
);

userApi.interceptors.response.use(
  responseFullfilled,
  flow([responseRejectedNotFound, responseRejected]),
);

instance.interceptors.request.use(requestFullfilledCustomHeaders);

instance.interceptors.response.use(responseFullfilled, responseRejected);

bffApi.interceptors.response.use(responseFullfilled, responseRejected);

fileApi.interceptors.request.use(requestFullfilledAuthHeader);

fileApi.interceptors.response.use(
  responseBlob,
  flow([responseRejectedNotFound, responseArrayBuffer, responseRejected]),
);

export { default as authApi } from './auth';
export { default as checkoutApi } from './checkout';

type ApiError = {
  data?: { status: number; code: string; message: string };
};

export const isApiError = (error: any): error is ApiError => error;

export default instance;
