import { Box, BoxProps } from '@chakra-ui/react';
import { createPortal } from 'react-dom';

type Props = BoxProps & {
  handleCloseModal?: () => void;
  isOpen?: boolean;
};

/**
 *
 * O componente é uma overlay que toma o tamanho inteiro da tela com o efeito de blur aplicado.
 * Normalmente, usado junto com modais e elementos que superpõem outros.
 *
 * @component
 * @param handleCloseModal type: () => void | undefined - Se presente, deve ser uma função para fechar o modal.
 * @param isOpen type: boolean | undefined - default = true.
 * @param props type: Todas do Box (Chakra UI).
 *
 * @see [Docs](./README.md) - Link to additional documentation.
 */

const BodyBluredOverlay = ({
  handleCloseModal,
  isOpen = true,
  ...props
}: Props) => {
  return (
    <>
      {createPortal(
        <Box
          bgColor="rgba(0,0,0,0.2)"
          w="100vw"
          bottom="0"
          position="fixed"
          top="0"
          left="0"
          zIndex="99"
          backdropFilter="blur(2px)"
          onClick={handleCloseModal}
          opacity={isOpen ? '1' : '0'}
          visibility={isOpen ? 'visible' : 'hidden'}
          transition="all .2s linear"
          {...props}
        />,
        document.body,
      )}
    </>
  );
};

export { BodyBluredOverlay };
