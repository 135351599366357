import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import { mode } from '@chakra-ui/theme-tools';
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleObject<typeof parts> = {
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'collapse',
    width: 'full',
  },
  th: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
    textAlign: 'start',
  },
  td: {
    textAlign: 'start',
  },
  caption: {
    mt: 4,
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'medium',
  },
};

const numericStyles: SystemStyleObject = {
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
};

const variantSimple: PartsStyleFunction<typeof parts> = props => {
  return {
    th: {
      color: mode('gray.900', 'gray.400')(props),
      ...numericStyles,
    },
    td: {
      ...numericStyles,
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const variantStripe: PartsStyleFunction<typeof parts> = props => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: mode('gray.600', 'gray.400')(props),
      ...numericStyles,
    },
    td: {
      borderBottom: '1px',
      ...numericStyles,
    },
    caption: {
      color: mode('gray.600', 'gray.50')(props),
    },
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          td: {
            background: mode(`${c}.50`, `${c}.700`)(props),
          },
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const variants = {
  simple: variantSimple,
  striped: variantStripe,
  unstyled: {},
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    th: {
      px: '5',
      py: '4',
      lineHeight: '4',
      fontSize: 'xs',
    },
    td: {
      px: '5',
      py: '2',
      fontSize: 'sm',
      lineHeight: '4',
    },
    caption: {
      px: '5',
      py: '2',
      fontSize: 'xs',
    },
  },
  md: {
    th: {
      px: '5',
      py: '4',
      lineHeight: '4',
      fontSize: 'sm',
    },
    td: {
      px: '5',
      py: '2',
      fontSize: 'md',
      lineHeight: '5',
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'sm',
    },
  },
  lg: {
    th: {
      px: '8',
      py: '4',
      lineHeight: '5',
      fontSize: 'sm',
    },
    td: {
      px: '8',
      py: '5',
      lineHeight: '6',
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'md',
    },
  },
};

const defaultProps = {
  variant: 'simple',
  size: 'md',
  colorScheme: 'gray',
};

const table = {
  parts: parts.keys,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};

export default table;
