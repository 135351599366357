import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import {
  Env,
  Integrations,
  // Storages
} from 'src/appConstants';

Sentry.init({
  dsn: Integrations.SENTRY_DSN,
  environment: Env,
  integrations: [new BrowserTracing()],
  tracesSampleRate: Env === 'production' ? 0.2 : 1.0,
});

// const FallbackError: React.FC = () => {
//   const height = use100vh();

//   const handleOnReload = () => {
//     window.localStorage.removeItem(Storages.USER);
//     window.localStorage.removeItem(Storages.URLS);
//     window.localStorage.removeItem(Storages.CHECKOUT);
//     window.localStorage.removeItem(Storages.CHECKOUT_FORM_VALUES);
//     window.location.reload();
//   };

//   return (
//     <Center flexDirection="column" height={height ? `${height}px` : '100vh'}>
//       <Text mb="5">Desculpe, tivemos algum erro inesperado.</Text>
//       <Button colorScheme="blue" rounded="full" onClick={handleOnReload}>
//         Recarregar
//       </Button>
//     </Center>
//   );
// };

const SentryProvider: React.FC = ({ children }) => {
  return <>{children}</>;
  // return (
  //   <Sentry.ErrorBoundary fallback={<FallbackError />}>
  //     {children}
  //   </Sentry.ErrorBoundary>
  // );
};

export default SentryProvider;
