import { useQuery } from '@tanstack/react-query';

import { userApi } from 'src/api';

import { useMeQuery } from './user';

const getInvoiceCount = () =>
  userApi.get('invoice/count').then(res => res.data);

export const useInvoiceCountQuery = (options = {}) => {
  const { data } = useMeQuery();
  return useQuery({
    queryKey: ['invoice/count', data?.email],
    queryFn: getInvoiceCount,
    enabled: !!data?.email,
    ...options,
  });
};
