import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import React from 'react';

import { useModal } from 'src/modules/modal';

const Prompt = ({
  title,
  body: Body,
  buttons: Buttons,
  onResolve,
  onReject,
  onClose,
}) => {
  const focusableRef = React.useRef();

  return (
    <AlertDialog
      isOpen
      isCentered
      motionPreset="slideInBottom"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      leastDestructiveRef={focusableRef}
      onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogBody>
          {!!Body && (
            <Body {...{ focusableRef, onResolve, onClose, onReject }} />
          )}
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="flex-start">
          {!!Buttons && (
            <Buttons {...{ focusableRef, onResolve, onClose, onReject }} />
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const usePrompt = () => {
  return useModal(Prompt);
};

export default usePrompt;
