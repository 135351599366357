
import { Text, TextProps } from '@chakra-ui/react';


const ErrorMessage: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <Text color="red" fontWeight="bold" fontSize="sm" mt="2" {...props}>
      {children}
    </Text>
  );
};

export { ErrorMessage };
