import { useState } from 'react';

import { useUserStore } from 'src/hooks/stores';

import {
  FloatingLabelContainer,
  FloatingLabelInput,
  FloatingLabel,
  ErrorMessage,
} from 'src/components/Inputs/FloatingLabel';
import { Button, Box, Heading, FormControl } from '@chakra-ui/react';
import { EllipsisLoading } from 'src/components/loadings';

import { PasswordInput } from 'src/components/Inputs/Password';
import { postAuth } from 'src/legacy/Components/auth/_api';
import ReactGA4 from 'react-ga4';
import { GoogleButton } from './Components/Buttons/GoogleButton';
import { useForm } from 'react-hook-form';
import { yup, yupResolver } from 'src/utils/form';

type LoginFormProps = {
  onLogIn: any;
  navigate: any;
};

type formSchema = {
  email: string;
  password: string;
};

const formValidationSchema = yup.object({
  email: yup.string().required('Preencha o email').email('Email inválido'),
  password: yup.string().required('Preencha a senha'),
});

const LoginForm: React.FC<LoginFormProps> = ({ onLogIn, navigate }) => {
  const { setUser } = useUserStore();
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<formSchema>({
    resolver: yupResolver(formValidationSchema),
  });

  const { onChange: onEmailChange, ...registerEmail } = register('email');
  const { onChange: onPasswordChange, ...registerPassword } =
    register('password');

  const onSubmit = handleSubmit(async data => {
    try {
      const authResponse = await postAuth(data.email, data.password);
      ReactGA4.event('login', { method: data.email });

      setUser(authResponse);

      reset();
      // talvez esse authResponse não precise..
      onLogIn?.(authResponse);
    } catch (er: any) {
      if (er?.response.status === 401) {
        setError('E-mail ou senha errados');
      } else {
        const message =
          er.response.data?.message ??
          'Ocorreu um erro inesperado. Verifique sua conexão e tente novamente.';
        setError(message);
      }
    }
  });

  return (
    <Box className="auth-section">
      <Heading mt="0">Login</Heading>
      <GoogleButton>Login com o Google</GoogleButton>
      <Box as="span" className="or">
        ou
      </Box>
      <Box as="form" data-testid="login-form" noValidate onSubmit={onSubmit}>
        <FormControl mb="4">
          <FloatingLabelContainer>
            <FloatingLabelInput
              type="email"
              data-testid="login-input-email"
              {...registerEmail}
              onChange={e => {
                onEmailChange(e);
                setError(null);
              }}
            />
            <FloatingLabel>Email</FloatingLabel>
          </FloatingLabelContainer>
          <ErrorMessage>{errors.email?.message}</ErrorMessage>
        </FormControl>

        <FormControl>
          <PasswordInput
            data-testid="login-input-password"
            {...registerPassword}
            onChange={e => {
              onPasswordChange(e);
              setError(null);
            }}
          />
          <ErrorMessage>{errors.password?.message ?? error}</ErrorMessage>
        </FormControl>

        <Button
          type="submit"
          mt="2"
          colorScheme="brand"
          rounded="full"
          w="full"
          isLoading={isSubmitting}
          spinner={<EllipsisLoading color="white" />}>
          Entrar
        </Button>
      </Box>
      <Box textAlign="center" mt="2">
        <Button
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
          color="brand.500"
          onClick={() => navigate('recovery')}>
          Esqueceu a senha?
        </Button>
      </Box>
      <Button
        textAlign="center"
        mt="0"
        w="full"
        bg="transparent"
        textColor="black"
        color="black"
        _hover={{ bg: 'transparent' }}
        _active={{ bg: 'transparent' }}
        onClick={() => navigate('signup')}>
        Não tem cadastro?{' '}
        <Box as="span" color="brand.500" ml="2">
          Cadastre-se
        </Box>
      </Button>
    </Box>
  );
};

export default LoginForm;
