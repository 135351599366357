import { isBrowser } from '@chakra-ui/utils';
import * as React from 'react';
import { render } from 'react-dom';

import { ToastManager } from './toast.manager';

const portalId = 'chakra-toast-portal';

class Toaster {
  createToast;

  constructor() {
    if (!isBrowser) return;

    let portal;
    const existingPortal = document.getElementById(portalId);

    if (existingPortal) {
      portal = existingPortal;
    } else {
      const div = document.createElement('div');
      div.id = portalId;
      document.body?.appendChild(div);
      portal = div;
    }
    
    render(<ToastManager notify={this.bindFunctions} />, portal);
  }

  bindFunctions = methods => {
    this.createToast = methods.notify;
  };

  notify = (toastComp, options = {}) => {
    this.createToast?.(toastComp, options);
  };
}

export const toast = new Toaster();
