import React from 'react';
import { create } from 'zustand';
import { Modal, ModalContent, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Sections } from 'src/legacy/Components/auth';
import LoginForm from 'src/components/AuthForms/LoginForm';
import RecoveryForm from 'src/components/AuthForms/RecoveryForm';
import SignupForm from 'src/components/AuthForms/SignupForm';
import { BodyBluredOverlay } from 'src/components/BodyBluredOverlay';

const initialState = {
  defaultSection: Sections.LOGIN,
  isOpen: false,
  closeOnOverlayClick: false,
};

export const useAuthModal = create(set => ({
  ...initialState,
  dummyResolver: null,
  resolvers: [],
  onOpen: () => set({ isOpen: true }),
  onLogin: () =>
    set({
      defaultSection: Sections.LOGIN,
      isOpen: true,
      closeOnOverlayClick: true,
    }),
  onSignup: () =>
    set({
      defaultSection: Sections.SIGNUP,
      isOpen: true,
      closeOnOverlayClick: true,
    }),
  onClose: () => set(initialState),
}));

export const AuthModal = () => {
  const { defaultSection, isOpen, closeOnOverlayClick, onClose, resolvers } =
    useAuthModal();
  const [section, setSection] = React.useState(defaultSection);

  const _onClose = authResponse => {
    onClose();
    // TODO: switch after full migration to react-query.
    // dummyResolver?.();
    resolvers.forEach(resolve => {
      resolve(authResponse);
    });
  };

  React.useEffect(() => {
    setSection(defaultSection);
  }, [defaultSection, isOpen]);

  return (
    <>
      <BodyBluredOverlay
        isOpen={isOpen}
        onClick={() => {
          _onClose();
        }}
      />
      {isOpen && (
        <Modal
          onClose={_onClose}
          isOpen={isOpen}
          isCentered
          motionPreset="slideInBottom"
          {...{ isOpen, closeOnOverlayClick }}>
          <ModalContent>
            <ModalBody pt={8}>
              {section === Sections.LOGIN && (
                <LoginForm navigate={setSection} onLogIn={_onClose} />
              )}
              {section === Sections.SIGNUP && (
                <SignupForm navigate={setSection} />
              )}
              {section === Sections.RECOVERY && (
                <RecoveryForm navigate={setSection} />
              )}
            </ModalBody>
            <ModalFooter />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
