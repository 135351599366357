let states = [];

var stateUF = [
  'Selecione um estado',
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO',
];
var stateName = [
  'Selecione um estado',
  'Acre',
  'Alagoas',
  'Amazonas',
  'Amapá',
  'Bahia',
  'Ceará',
  'Distrito Federal',
  'Espírito Santo',
  'Goiás',
  'Maranhão',
  'Minas Gerais',
  'Mato Grosso do Sul',
  'Mato Grosso',
  'Pará',
  'Paraíba',
  'Pernambuco',
  'Piauí',
  'Paraná',
  'Rio de Janeiro',
  'Rio Grande do Norte',
  'Rondônia',
  'Roraima',
  'Rio Grande do Sul',
  'Santa Catarina',
  'Sergipe',
  'São Paulo',
  'Tocantins',
];

for (const i in stateUF) {
  states.push({
    option: stateName[i],
    value: stateUF[i],
  });
}

let hours = [];

for (let i = 0; i < 24; i++) {
  hours.push({
    option: `${('0' + i).slice(-2)}:00`,
    value: `${('0' + i).slice(-2)}:00`,
  });

  hours.push({
    option: `${('0' + i).slice(-2)}:30`,
    value: `${('0' + i).slice(-2)}:30`,
  });
}

const fills = {
  states,
  hours,
};

export default fills;
